<template>
  <div id="requisitions" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-col cols="12" class="pa-0">
      <v-sheet class="d-flex" color="amber darken-2" height="70" tile>
        <h1 class="white--text ml-7">{{ nombrePuesto }}</h1>
      </v-sheet>
    </v-col>
    <v-container class="mt-n5">
      <v-col cols="12" sm="12">
        <v-container class="detailResume">
          <v-row justify="center"><label>INFORMACIÓN DETALLADA</label></v-row>
          <v-textarea rounded rows="10" v-model="datos"> </v-textarea>
          <v-row justify="end"
            ><button class="botonAmarilloDerecho">Guardar</button></v-row
          >
        </v-container>
        <!--<v-textarea
          background-color="#D0D0D0"
          rounded
          rows="16"
          id="resumen"
          v-model="datos"
        >
        </v-textarea
      >--></v-col
      >
    </v-container>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      idEmpleado: localStorage.empleadoIdGlobal,
      enterprise: localStorage.empresaIdGlobal,
      escolaridad: "",
      datos: "",
      idRequisicion: localStorage.requisicionId,
      nombrePuesto: "",
      nombreEmpresa: "",
      funciones: [],
      habilidades: [],
      beneficios: [],
      enterpriseSelect: [],
      jobSelect: [],
      prestacion: 0,
      r1: 0,
      r2: 0,
      puesto: 0,
      direccion: "",
      auxHabilidad:"",
      auxFuncion:"",
      beneficiosAux:""
    };
  },
  methods: {
    empresa() {
      this.show = true;
      axios
        .get(Server + "/empresas/" + localStorage.empresaIdGlobal, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterpriseSelect = response.data;
          this.nombreEmpresa = response.data.razonSocial;
          this.show = false;
          if (this.enterpriseSelect.codigoPostal.length == 5) {
            this.show = true;
            axios
              .get(
                Server + "/direcciones/" + this.enterpriseSelect.codigoPostal,
                {
                  headers: {
                    Authorization: localStorage.token,
                  },
                }
              )
              .then((responseD) => {
                this.direccion =
                  "Calle " +
                  this.enterpriseSelect.calle +
                  " No.Ext " +
                  this.enterpriseSelect.numeroExterior +
                  " No.Int " +
                  this.enterpriseSelect.numeroInterior +
                  " Colonia " +
                  responseD.data[0].colonia +
                  " Municipio " +
                  responseD.data[0].municipio +
                  " " +
                  responseD.data[0].estado;
                this.show = false;
              })
              .catch((e) => {
                console.log(e);
                this.show = false;
                if (e.response.status == 401) {
                  this.expiration = true;
                }
              });
          } else {
            this.direccion =
              "Calle " +
              response.data.calle +
              " No.Ext " +
              response.data.numeroExterior +
              " No.Int " +
              response.data.numeroInterior;
            console.log(this.direccion);
          }
        })
        .catch((e) => {
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    paquetesPrestaciones() {
      this.show = true;
      axios
        .get(Server + "/paquetesPrestaciones/" + this.prestacion, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          response.data.prestaciones.forEach((element) => {
            this.beneficios.push(element.prestacion.nombrePrestacion);
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerFunciones() {
      this.funciones = [];
      this.show = true;
      axios
        .get(Server + "/funcionesPuestos/por-puesto/" + this.puesto, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          response.data.forEach((element) => {
            this.funciones.push(element.descripcionActividad);
          });
          this.show = false;
          this.obtenerHabilidades();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerHabilidades() {
      this.habilidades = [];
      this.show = true;
      axios
        .get(Server + "/habilidadesPuestos/por-puesto/" + this.puesto, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var habilidades = response.data;
          this.habilidades = [];
          habilidades.forEach((habilidad) => {
            this.habilidades.push(habilidad.descripcionHabilidad);
          });
          this.show = false;
          this.datosGenerales();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    recuperar() {
      this.empresa();
      this.show = true;
      this.puesto = localStorage.puestoId;
      //console.log(this.puesto, "puesto");
      axios
        .get(Server + "/requisicionesVacantes/" + this.idRequisicion, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.nombrePuesto = response.data.puestoVacante.nombrePuesto;
          this.puesto = response.data.puestoVacanteId;
          this.prestacion = response.data.paquetePrestacionesId;
          this.r1 = response.data.rangoSueldoMin;
          this.r2 = response.data.rangoSueldoMax;
          this.escolaridad = response.data.escolaridad.nombreEscolaridad;
          this.show = false;
          this.paquetesPrestaciones();
          this.obtenerFunciones();
          this.obtenerHabilidades();
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    datosGenerales() {
      this.auxHabilidad ="";
      for (var i = 0; i < this.habilidades.length; i++) {
        this.auxHabilidad += "\n" + (i + 1) + ".-" + this.habilidades[i] + "";
        
      }
      this.auxFuncion="";
      for (var i = 0; i < this.funciones.length; i++) {
        this.auxFuncion =
          this.auxFuncion + "\n" + (i + 1) + ".-" + this.funciones[i] + "";
      }
      this.beneficiosAux = "";
      for (var i = 0; i < this.beneficios.length; i++) {
        this.beneficiosAux =
          this.beneficiosAux + "\n" + (i + 1) + ".-" + this.beneficios[i] + "";
      }
      this.datos =
        "Nombre de la Empresa: " +
        this.nombreEmpresa +
        "\nNombre del Puesto: " +
        this.nombrePuesto +
        "\nEscolaridad: " +
        this.escolaridad +
        "\nHabilidades: " +
        this.auxHabilidad +
        "\nFunciones: " +
        this.auxFuncion +
        "\nRango de Sueldo: " +
        this.r1 +
        " - " +
        this.r2 +
        "\nPrestaciones: " +
        this.beneficiosAux +
        "\nUbicación: " +
        this.direccion;
    },
  },
  created() {
    this.recuperar();
  },
};
</script>
<style>
.v-application.primary--text {
  color: orange !important;
}
</style>